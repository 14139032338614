


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';

@Component({
  components: {
    InputField,
  },
})
export default class ProductsSearch extends Vue {
  /**
   * Props
   */
  @Prop() private query!: string;
  @Prop() private category!: CategoryCoreModel | null;

  /**
   * Data
   */
  private value: string = this.query;

  /**
   * Getters
   */
  private get placeholder(): string {
    return 'Szukaj';

    // return this.category
    //     ? `Szukaj w: ${this.category.name}`
    //     : 'Szukaj we wszystkich produktach';
  }

  /**
   * Handlers
   */
  private onFormSubmit(e: Event) {
    e.preventDefault();
    this.$emit('input', this.value);
  }

  private onClickClear() {
    this.$emit('input', null);
  }

  /**
   * Watchers
   */
  @Watch('query')
  private watchQueryChange(query: string) {
    this.value = query;
  }
}
