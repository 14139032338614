









































































































import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import CategoriesTree from '@/app/modules/categories/components/CategoriesTree.vue';
import ProductsList from '@/app/modules/products/components/ProductsList.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Tooltip from '@/shared/resources/components/Tooltip.vue';
import ProductsSearch from '@/app/modules/products/components/partials/ProductsSearch.vue';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';
import { isEqual } from 'lodash';
import ProductsListViewEnum from '@/app/modules/products/enums/ProductsListViewsEnum';
import UISettings from '@/app/lib/settings/UISettings';
import SideModal from '@/shared/resources/components/SideModal.vue';
import ProductsListFilters from '@/app/modules/products/components/ProductsListFilters.vue';

@Component({
  components: {
    ProductsListFilters,
    SideModal,
    ProductsSearch,
    Tooltip,
    Icon,
    Button,
    InputField,
    GridCol,
    GridRow,
    MainHeader,
    ProductsList,
  },
})
export default class ProductsView extends Vue {
  /**
   * Data
   */
  private category: CategoryCoreModel | null = null;
  private searchQuery: string | null = null;
  private filtersVisible: boolean = false;

  /**
   * Getters
   */
  private get title(): string {
    if (!!this.searchQuery) {
      return 'Wyniki wyszukiwania'; // TODO translate
    }

    return this.category ? this.category.name : 'Wszystkie produkty'; // TODO translate
  }

  private get subtitle(): string {
    if (!!this.searchQuery) {
      return 'Dla zapytania: ' + this.searchQuery; // TODO translate
    }

    return 'Produkty'; // TODO translate
  }

  private get categoryId(): number | null {
    return this.$route.params.id
      ? Number(this.$route.params.id)
      : null;
  }

  private get view(): ProductsListViewEnum {
    return UISettings.productsView ?? ProductsListViewEnum.LIST;
  }

  private get viewButtonIcon(): string {
    return this.view === ProductsListViewEnum.GRID ? 'list' : 'apps';
  }

  private get viewButtonTooltip() {
    return this.view === ProductsListViewEnum.GRID ? 'Lista' : 'Kafelki'; // TODO translate
  }

  /**
   * Display getters
   */
  private get displayMobileButtons(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get displayMobileCategoriesButton(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get displayMobileSearch(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get displayDesktopSearch(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      UISettings.drawerVisible = false;
    }
  }

  /**
   * Methods
   */
  private toggleView() {
    UISettings.productsView = this.view === ProductsListViewEnum.GRID
      ? ProductsListViewEnum.LIST
      : ProductsListViewEnum.GRID;
  }

  private showFiltersModal() {
    this.filtersVisible = true;
  }

  private hideFiltersModal() {
    this.filtersVisible = false;
  }

  /**
   * Handlers
   */
  private onGetCategory(category: CategoryCoreModel) {
    if (!isEqual(category, this.category)) {
      this.searchQuery = null;
    }

    this.category = category;
  }

  private onSearchInput(searchQuery: string | null) {
    this.searchQuery = searchQuery;
  }

  private onCategoriesButtonClick() {
    UISettings.drawerVisible = true;
  }

  private onChangeViewButtonClick() {
    this.toggleView();
  }

  private onShowFiltersButtonClick() {
    this.showFiltersModal();
  }
}
